body {
    font-family: Arial, sans-serif;
    background: #1a1a1a;
    color: white;
    margin: 0;
    padding: 0;
  }
  
  .gallery-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .pending-tasks, .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Ensures 2 images per row */
    gap: 20px;
  }

  .image-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Ensures 2 images per row */
    gap: 20px;
  }

  .digital-model-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Ensures 2 images per row */
    gap: 40px;
  }

  .task-card, .image-card {
    display: flex;
    flex-direction: column;
    background: #2c2c2c;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .digital-model-card {
    background: #2c2c2c;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .task-card:hover, .image-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .task-card img, .image-card, .digital-model-card img {
    width: 256px;
    height: 256px;
    object-fit: cover;
    border-bottom: 2px solid #007bff;
  }
  
  .image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .overlay-content {
    padding: 20px;
    max-width: 80%;
    text-align: center;
    background: white;
    border-radius: 10px;
    color: black;
    display: inline-block;
  }
  
  .overlay-content img {
    max-height: 80vh;
    max-width: 100%;
    border-radius: 10px;
  }
  
  .overlay-content p, .overlay-content span {
    color: black;
    margin-top: 20px;
    display: block;
  }
  
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #2c2c2c;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #444;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: darkred;
}
