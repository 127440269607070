body {
  font-family: Arial, sans-serif;
  background: #1a1a1a;
  color: white;
  margin: 0;
  padding: 0;
}

.service-menu-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.service-card {
  background: #2c2c2c;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card-grayed-out {
  background: rgb(92, 90, 90);
  color: #000;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-image {
  width: 180px;
  height: 240px; /* Adjust height to ensure consistency */
  object-fit: cover; /* Ensure the image covers the area while maintaining its aspect ratio */
}

.service-image-blurred {
  filter: blur(24px);
  width: 180px;
  height: 240px; /* Adjust height to ensure consistency */
  object-fit: cover; /* Ensure the image covers the area while maintaining its aspect ratio */
}

.service-info {
  padding: 15px;
  text-align: center;
}

.service-info h3 {
  margin: 10px 0 5px;
  font-size: 1.2em;
}

.service-info p {
  margin: 0;
  color: #ccc;
}

.service-info img {
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
  margin-right: 10px;
}

