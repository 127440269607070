.loading-spinner {
    text-align: center;
    margin-top: 20px;
  }
  
  .spinner {
    margin: 20px auto;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  .spinner-large {
    margin: 20px auto;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    width: 80;
    height: 80px;
    animation: spin 1s linear infinite;
  }


  @keyframes spin {
    to { transform: rotate(360deg); }
  }
