body {
    font-family: Arial, sans-serif;
    background: #1a1a1a;
    color: white;
    margin: 0;
    padding: 0;
  }
  
  .faceswap-gallery-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Ensures 2 images per row */
    gap: 10px; /* Reduced gap between the cards */
    padding: 0; /* Ensure no padding around the grid */
    margin: 0; /* Ensure no margin around the grid */
  }
  
  .image-card {
    background: #2c2c2c;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Add Flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%; 
    padding: 0; /* Remove any default padding */
    margin: 0; /* Remove any default margin */
  }
  
  .image-card img {
    max-width: 100%;
    max-height: 100%;
    margin: 0; /* Remove any default margin */
  }
  
  
  /* .image-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  } */

  
  .image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .overlay-content {
    padding: 20px;
    max-width: 80%;
    text-align: center;
    background: #2c2c2c;
    border-radius: 10px;
  }
  
  .overlay-content img {
    max-height: 80vh;
    max-width: 100%;
    border-radius: 10px;
  }
  
  .overlay-content p, .overlay-content span {
    color: white;
    margin-top: 20px;
    display: block;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #2c2c2c;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #444;
}
