body {
    font-family: Arial, sans-serif;
    background: #f4f4f9;
    margin: 0;
    padding: 0;
  }
  
  .generation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* max-width: 1024px; */
    margin: 20px auto;
    color: #000;
  }

  .generation-container label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
    color: #000;
  }
  
  .input-container, .preview-container {
    width: 100%;
    margin-bottom: 20px;
    color: #000;
    padding-right: 20px;
  }
  
  #textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .input-container textarea, input {
    flex: 1;
    height: 100%; /* Set height to 100% to match the height of the image */
    resize: none; /* Optional: Prevent resizing */
    font-size: 16px;
  }
  
  #imageInput {
    display: block;
    margin-top: 10px;
  }
  
  /* .preview-container img {
    display: block;
    max-width: 100%;
    max-height: 300px;
    margin-top: 10px;
    border-radius: 10px;
  } */

  .preview-container img {
    max-width: 100%; /* Ensure the image does not overflow */
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: auto;
  }

  .row {
    display: flex;
    display: flex;
    align-items: stretch; /* Ensure all children stretch to the same height */
    gap: 20px; /* Add some space between the containers */
  }
  
  .preview-container, .input-container {
    flex: 1; /* This allows the containers to take up equal space */
    display: flex;
    flex-direction: column; /* Ensure children are stacked vertically */
  }

  input[type="file"], input[type="text"], select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin: 5px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
  }