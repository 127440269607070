.image-editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .image-masker-container {
    position: relative;
    /* width: 512px;
    height: 512px; */
    border: 1px solid #ccc;
  }
  
  .input-container {
    width: 512px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .input-container label {
    margin-bottom: 5px;
  }
  
  .input-container textarea,
  .input-container select,
  .input-container input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .button-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button-container button:hover {
    background-color: #0056b3;
  }
  
  .message {
    margin-top: 10px;
    color: red;
  }
  