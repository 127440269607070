.faceswap-gallery-container {
    padding: 20px;
    max-width: 600px;

  }
  
  .video-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .video-card, .image-card {
    flex: 1 1 calc(45% - 20px); /* Two columns layout with space for padding */
    box-sizing: border-box;
    padding: 10px;
    background: #333;
    border-radius: 10px;
    cursor: pointer;
    max-width: calc(50% - 20px);
  }
  
  .video-card.last-single, .image-card.last-single {
    flex: 1 1 calc(45% - 20px); /* Ensure last single item doesn't stretch */
    max-width: calc(45% - 20px);
  }
  
  .video-card video, .image-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: center;
  }
  
  .full-screen-video {
    width: 100%;
    height: auto;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #2c2c2c;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #444;
}
