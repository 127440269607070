.home-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: center;
    position: relative;
    width: 60%;
    /* max-width: 1024px; */
    margin: auto;
    overflow: hidden;
  }
  
  .feature-row {
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 100%;
  }
  
  .feature-title,
  .feature-image {
    flex: 1;
    padding: 20px;
  }
  
  .feature-title {
    font-size: 2.2em;
    font-weight: bold;
    text-align: left;
    color: #fff;
    /* background: linear-gradient(to right, #f8f8f8, #eaeaea);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }

  .feature-text {
    font-size: 0.6em;
    /* font-weight: bold; */
    color: #d8d5d5;
    padding: 15px 0px;
    text-align: left;

    /* background: linear-gradient(to right, #f8f8f8, #eaeaea);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }

  .feature-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feature-separator {
    width: 80%;
    margin: 20px auto;
    border: 0;
    border-top: 1px solid #ccc;
  }
  
  .text-left .feature-title {
    order: 1;
    text-align: left;
  }
  
  .text-left .feature-image {
    order: 2;
  }
  
  .text-right .feature-title {
    order: 2;
    text-align: left;
  }

  .text-right .feature-image {
    order: 1;
  }
  
  @media (max-width: 768px) {
    .feature-row {
      flex-direction: column; /* Stack items vertically on small screens */
    }
  
    .text-left .feature-title,
    .text-left .feature-image,
    .text-right .feature-title,
    .text-right .feature-image {
      order: initial; /* Reset order for mobile view */
      text-align: center; /* Center align text on small screens */
    }
  
    .feature-title,
    .feature-image {
      padding: 10px; /* Adjust padding for smaller screens */
    }
  
    .feature-separator {
      width: 100%;
      margin: 10px 0;
    }
  }
  