body {
    font-family: Arial, sans-serif;
    background: #1a1a1a;
    color: white;
    margin: 0;
    padding: 0;
  }
  
  .workflow-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background: #2c2c2c;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .workflow-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .input-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .image-upload {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .image-upload label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #f4f4f9;
  }
  
  .image-upload input {
    display: block;
    margin: 0 auto 10px;
    background: #3b3b3b;
    color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
  }
  
  .image-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-previews img {
    display: block;
    max-width: 100px;
    max-height: 100px;
    border-radius: 5px;
  }
  
  .button-section {
    text-align: center;
  }
  
  .submit-button, .back-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin: 10px;
  }
  
  .submit-button:hover, .back-button:hover {
    background-color: #0056b3;
  }
  
  .message {
    margin-top: 20px;
    text-align: center;
  }
  
  .error {
    color: red;
  }
  
  .success {
    color: green;
  }
  

  