.feed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: center;
    position: relative;
    width: 60%;
    /* max-width: 1024px; */
    margin: 0 auto;
    overflow: hidden;
  }

  
  .feed-row {
    display: flex;
    align-items: top;
    margin: 10px 0;
    width: 100%;
  }

  .feed-row-profile-image {
    display: flex;
    justify-content: top;
    align-items: top;
    text-align: top;
    margin: 20px 0;
    width: 100px;
    padding: 10px;
  }

  .feed-row-profile-image img {
    display: flex;
    justify-content: top;
    max-width: 100px;
    height: 100px;
    border-radius: 10px;
  }

  .feed-row-title{
    text-align: left;
    align-items: left;
    margin: 20px 0;
    width: 100%;
    padding-top: 10px;
    font-weight: 900;
  }

  .feed-row-text{
    text-align: left;
    align-items: left;
    margin: 20px 0;
    width: 100%;
  }

  .feed-row-lastline {
    display: flex;
    margin: 10px 0;
    width: 100%;
  }

  .feed-row-timestamp {
    text-align: left;
    align-items: left;
    font-size: 13px;
    font-weight: 400;
    /* margin: 20px 0; */
    width: 100%;
  }

  .feed-row-comment {
    display: flex;
    margin: 10px 0;
    width: 100%;
    text-align: left;
    align-items: left;
  }


  .feed-row-addcomment {
    background-color: grey;
    text-align: right;
    align-items: right;
    /* margin: 10px 0; */
    width: 20px;
    font-size: 14px;
    font-weight: 900;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .comment-error-message {
    color:yellow
  }

  .comment-line {
    display: flex;
    margin: 10px 0;
    width: 100%;
  }

  .comment-line input{
    /* margin: 10px 0; */
    height: 14px;
    width: 100%;
  }

  .photo-grid {
    display: grid;
    grid-template-columns: repeat(3, 3fr); /* Ensures 2 images per row */
    gap: 10px;
  }

  .photo-grid img {
    max-width: 100px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  
