body {
  font-family: Arial, sans-serif;
  background: #1a1a1a;
  color: white;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.App-header h1 {
  margin: 0;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 0.9em;
}

.username {
  font-weight: bold;
}

.copyright {
  font-weight: bold;
  color: #fff;
  padding: 20px;
}

.points {
  background: #007bff;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  /* height: 80vh; */
}

.auth-form {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.auth-form h2 {
  margin-bottom: 20px;
}

.auth-form input {
  width: 90%;
  max-width: 350px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none; /* Optional: Prevent resizing */
  font-size: 16px;

}

.auth-form button {
  width: 90%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-form p {
  color: #007bff;
  cursor: pointer;
}

.auth-form p:hover {
  text-decoration: underline;
}

.slogan {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.error-message {
  color: red;
}

.image-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.slogan-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slogan-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.slogan {
  margin: 20px 0;
  border-radius: 10px;
  padding: 20px;
}

.user-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  color: #000;
}

.popup-content button {
  margin-top: 10px;
}

.slogan {
  text-align: center;
  font-size: 3em;
  margin-bottom: 20px;
}

.features-section {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* gap: 20px; /* Adjust the gap between the features */
  /* margin-top: 20px; */ 
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Ensures 2 images per row */
  gap: 10px;  
  align-items: center;
}



.feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.sub-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
}

.feature-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.tags-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.tag {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.auth-container {
  margin-top: 20px;
}

input {
  resize: none; /* Optional: Prevent resizing */
  font-size: 16px;
}