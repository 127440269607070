  
  .chat-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: grey;
  }
  
  .chat-window {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;
  }
  
  .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 400px;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 10px;
    border: none;
    border-top: 1px solid #ccc;
  }
  
  button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .message.Error {
    color: #dc3545;
  }
  
  .profile-image {
    width: 100px;
    padding: 10px;
  }



/* .chat-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
} */

.chat-bubble {
    max-width: 60%;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
    display: inline-block;
}

.left {
    align-self: flex-start;
    background-color: #f0f0f0;
    color: black;
    text-align: left;
}

.right {
    align-self: flex-end;
    background-color: #d1f7d1;
    color: black;
    text-align: right;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-message {
    display: flex;
    align-items: center;
}

.right .chat-message {
    flex-direction: row-reverse;
}