.paypal-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .paypal-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .paypal-message {
    margin-bottom: 20px;
    padding: 10px;
    color: red;
    background-color: #ffe6e6;
    border: 1px solid red;
    border-radius: 5px;
  }
  
  .paypal-dropdown-container {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .paypal-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .paypal-dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .paypal-button-container {
    display: flex;
    justify-content: center;
  }
  
  .membership-description {
    color: black;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Ensures 2 images per row */
    gap: 20px;
  }
  
  .membership-description h3 {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .membership-description ul {
    padding-left: 20px;
  }
  
  .membership-description li {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .membership-description img {
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
    margin-right: 10px;
  }
  
  