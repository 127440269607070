.features-page {
    text-align: center;
  }
  
  .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  
  .feature-card {
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* width: 100px; */
    margin: 10px;
    padding: 20px;
    text-align: center;
    /* display: flex; */
  }
  
  .feature-icon {
    font-size: 50px;
    margin-bottom: 10px;
  }

  .feature-icon img {
    width: 80px;
    height: 80px;
  }

  .feature-title1 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #000;
  }
  
  .feature-description {
    color: rgb(255, 255, 0);
  }
  
  .feature-card:hover {
    background-color: #f9f9f9;
  }
  
  .feature-card-grayed-out {
    background: rgb(92, 90, 90);
    color: #000;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* width: 200px; */
    margin: 10px;
    padding: 20px;
    text-align: center;
    /* display: flex; */
  }

  .feature-description img {
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
    margin-right: 10px;
  }

/* Optional: Specific styles for smaller screens */
@media (max-width: 768px) {
  .feature-card {
    width: calc(100% - 20px); /* Full width on smaller screens */
  }
}