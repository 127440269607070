body {
    font-family: Arial, sans-serif;
    background: #1a1a1a;
    color: white;
    margin: 0;
    padding: 0;
  }
  
  .workflow-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background: #2c2c2c;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .workflow-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .row-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .target-card, .source-grid {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
    color: #333;
  }
  
  .target-card label, .source-grid label {
    display: block;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .target-card img, .source-item img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 5px;
  }
  
  .source-grid {
    display: flex;
    flex-direction: column;
  }
  
  .source-faces {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .source-item {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
  }
  
  .source-item img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
  }
  
  .source-item.selected {
    border-color: #ff0000;
    border-width: 5px;
  }
  
  .submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin: 10px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  